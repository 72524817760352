import { shopProducts } from '@/signals/products';
import { RangeSlider } from './RangeSlider';
import s from './FilteredProducts.module.css';
import { categories, getCategoryById } from '@/signals/categories';
import classNames from 'classnames';
import { brandsWithproducts } from '@/signals/brands';
import { Category } from '@/types';
import { cart } from '@/signals/cart';
import { viewport } from '@/signals/viewport';
import { mobileFiltersVisible } from '@/signals/ui';
import { COMPANY_ID } from '@/constants';
import { Brand, Product } from 'cosdb-types';
import { useRouter } from 'preact-router';
import pick from 'lodash.pick';
import { addQueryParam, addValueToArrayQueryParam, removeQueryParam, removeValueFromArrayQueryParam } from '@/utils';
import { ProductStatuses } from './product-statuses';
import { ProductPricesWithCta } from './product-prices-with-cta';
import { ProductName } from './product-name';

type Filters = {
  initialMin?: number;
  initialMax?: number;
  min?: number;
  max?: number;
  types: string[];
  problems: string[];
  components: string[];
  brands: string[];
  search?: string;
};

const getFilteredProducts = ({ min, max, brands, components, problems, types, search }: Filters) => {
  let res = shopProducts.value;

  [types, problems, components].forEach((categories: string[]) => {
    if (categories?.length) {
      res = res.filter((p) => {
        if (p.categoryIds === undefined) return false;

        return categories.some((cat) => p.categoryIds!.includes(cat));
      });
    }
  });

  if (brands?.length) {
    res = res.filter((p) => brands.includes(p.brandId));
  }

  if (min !== undefined) {
    res = res.filter((p) => p.price >= min);
  }

  if (max !== undefined) {
    res = res.filter((p) => p.price <= max);
  }

  if (search) {
    res = res.filter((p) => p.name.toLowerCase().includes(search));
  }

  return res;
};

export type FilteredProductsCategory = 'problem' | 'brand' | 'component' | 'type';

export type FilteredProductsProps = {
  [K in FilteredProductsCategory]?: string;
};

export const FilteredProducts = ({ brand, component, problem, type }: FilteredProductsProps) => {
  const [route, navigate] = useRouter();
  const queryParamMin = (route.matches?.min && parseInt(route.matches?.min, 10)) || undefined;
  const queryParamMax = (route.matches?.max && parseInt(route.matches?.max, 10)) || undefined;

  const _filters: Filters = {
    brands: route.matches?.brands?.split(',') || [],
    components: route.matches?.components?.split(',') || [],
    types: route.matches?.types?.split(',') || [],
    problems: route.matches?.problems?.split(',') || [],
    search: route.matches?.search || '',
    min: queryParamMin,
    max: queryParamMax,
  };

  const onClearSearch = () => {
    navigate(removeQueryParam(route.url, 'search'));
  };

  const onAddToCart = (product: Product) => {
    cart.value = [...cart.value, product.id];
  };

  const filteredByMainFilters = getFilteredProducts(
    pick(_filters, 'brands', 'components', 'problems', 'types', 'search') as Filters,
  );

  const filteredNotByBrands = getFilteredProducts(
    pick(_filters, 'components', 'problems', 'types', 'search') as Filters,
  );

  const filteredNotByComponents = getFilteredProducts(
    pick(_filters, 'brands', 'problems', 'types', 'search') as Filters,
  );

  const filteredNotByTypes = getFilteredProducts(
    pick(_filters, 'brands', 'problems', 'components', 'search') as Filters,
  );

  const filteredNotByProblems = getFilteredProducts(
    pick(_filters, 'brands', 'components', 'types', 'search') as Filters,
  );

  const availableBrands = [...new Set(filteredNotByBrands.map((p) => p.brandId))];
  const availableComponents = [
    ...new Set(
      filteredNotByComponents
        .flatMap((p) => p.categoryIds)
        .filter((id) => getCategoryById(id || '')?.type === 'component'),
    ),
  ];
  const availableTypes = [
    ...new Set(
      filteredNotByTypes.flatMap((p) => p.categoryIds).filter((id) => getCategoryById(id || '')?.type === 'type'),
    ),
  ];
  const availableProblems = [
    ...new Set(
      filteredNotByProblems.flatMap((p) => p.categoryIds).filter((id) => getCategoryById(id || '')?.type === 'problem'),
    ),
  ];
  const available = [
    ...availableBrands,
    ...availableComponents,
    ...availableTypes,
    ...availableProblems,
    ..._filters.brands,
    ..._filters.components,
    ..._filters.types,
    ..._filters.problems,
  ];

  const min = Math.min(...filteredByMainFilters.map((p) => p.price));
  const max = Math.max(...filteredByMainFilters.map((p) => p.price));

  const filtersComponent = (
    <div class="list-group">
      <div class="list-group-item py-3">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Назва товару..."
            name="search"
            value={_filters.search}
            onChange={(e) => {
              const value = (e.target as HTMLInputElement)?.value;
              const newUrl = value ? addQueryParam(route.url, 'search', value) : removeQueryParam(route.url, 'search');

              navigate(newUrl);
            }}
          />
          {_filters.search && (
            <button class="btn btn-outline-secondary" onClick={onClearSearch}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </button>
          )}
          <button class="btn btn-outline-secondary" type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </button>
        </div>
      </div>

      {min !== max && filteredByMainFilters.length > 1 && (
        <div class="list-group-item py-3">
          <b>Ціна</b>
          <RangeSlider
            defaultMin={queryParamMin}
            defaultMax={queryParamMax}
            min={min}
            max={max}
            onChange={({ min, max }) => {
              const newWithMin = addQueryParam(route.url, 'min', min.toString());
              const newWithMinAndMax = addQueryParam(newWithMin, 'max', max.toString());

              navigate(newWithMinAndMax);
            }}
          />
        </div>
      )}

      {[
        {
          condition: brand && viewport.value !== 'mobile',
          label: 'Бренд',
          list: brandsWithproducts.value,
          key: 'brands',
        } as { condition?: string; label: string; list: Brand[]; key: 'brands' },
        {
          condition: type && viewport.value !== 'mobile',
          label: 'Тип догляду',
          list: categories.value.filter((c) => c.type === 'type'),
          key: 'types',
        } as { condition?: string; label: string; list: Category[]; key: 'types' },
        {
          condition: problem && viewport.value !== 'mobile',
          label: 'Тип проблеми',
          list: categories.value.filter((c) => c.type === 'problem'),
          key: 'problems',
        } as { condition?: string; label: string; list: Category[]; key: 'problems' },
        {
          condition: component && viewport.value !== 'mobile',
          label: 'Компоненти',
          list: categories.value.filter((c) => c.type === 'component'),
          key: 'components',
        } as { condition?: string; label: string; list: Category[]; key: 'components' },
      ].map((mapper) => {
        if (mapper.condition) return null;

        const items = mapper.list.filter((i) => available.includes(i.id));

        if (!items.length) return null;

        return (
          <div class="list-group-item py-3" key={mapper.key}>
            <b>{mapper.label}</b>
            <div class={s.FilteredProducts__filter}>
              {items.map((b) => {
                const active = _filters[mapper.key]?.includes(b.id);

                return (
                  <button
                    key={b.id}
                    type="button"
                    class={classNames('btn btn-sm', {
                      'btn-outline-secondary': !active,
                      'btn-success': active,
                    })}
                    onClick={() => {
                      if (active) {
                        const newUrl = removeValueFromArrayQueryParam(route.url, mapper.key, b.id);

                        navigate(newUrl);
                      } else {
                        const newUrl = addValueToArrayQueryParam(route.url, mapper.key, b.id);

                        navigate(newUrl);
                      }
                    }}
                  >
                    {b.value}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );

  const filteredProducts = getFilteredProducts({ ..._filters });

  const productsComponent = (
    <div class={s.FilteredProducts__productsGrid}>
      {filteredProducts.map((p) => {
        return (
          <div
            data-available={p.available}
            class={classNames('card', s.productCard)}
            key={p.id}
            onClick={() => navigate(addQueryParam(route.url, 'productId', p.id))}
          >
            <ProductStatuses product={p} />
            {p.thumbnail && (
              <img
                style={{ aspectRatio: '2 / 1', objectFit: 'contain', backgroundColor: '#ffffff' }}
                src={`https://storage.googleapis.com/cosdb2-thumbnails/c/${COMPANY_ID}/products/${(p.thumbnail || '').split('.')[0]}_640x640.webp`}
                class="card-img-top border-bottom"
              />
            )}
            <div class="card-body">
              <ProductName product={p} size="h5" />
              <p
                class="card-text"
                style={{
                  display: '-webkit-box',
                  maxWidth: '100%',
                  '-webkit-line-clamp': 3,
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                }}
              >
                {p.description}
              </p>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <ProductPricesWithCta product={p}>
                  {cart.value.find((id) => id === p.id) ? (
                    <button class="btn btn-outline-primary" disabled>
                      Товар в кошику
                    </button>
                  ) : (
                    <button
                      class="btn btn-primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddToCart(p);
                      }}
                    >
                      Додати в кошик
                    </button>
                  )}
                </ProductPricesWithCta>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  if (viewport.value === 'mobile') {
    return (
      <>
        {mobileFiltersVisible.value && (
          <>
            <div class="modal-backdrop show" style={{ display: 'block', zIndex: 999 }} />
            <div
              class="modal"
              style={{ display: 'block', zIndex: 999 }}
              onClick={() => (mobileFiltersVisible.value = false)}
            >
              <div class="modal-dialog" onClick={(e) => e.stopPropagation()}>
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLiveLabel">
                      Фільтри
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      onClick={() => (mobileFiltersVisible.value = false)}
                    ></button>
                  </div>
                  <div class="modal-body" id="fitlers-modal">
                    {filtersComponent}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {productsComponent}
      </>
    );
  }

  return (
    <div class="row mb-5">
      <div class="col-4">{filtersComponent}</div>
      <div class="col-8">{productsComponent}</div>
    </div>
  );
};
