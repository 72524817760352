import { orders } from '@/signals/orders';
import { getProductById2 } from '@/signals/products';
import { removeQueryParam } from '@/utils';
import { useRouter } from 'preact-router';
import { useState } from 'preact/hooks';
import { ProductListItem } from './product-list-item';

const params = new URLSearchParams(window.location.search);

export const Order = () => {
  const [route, navigate] = useRouter();
  const [visible, setVisible] = useState<boolean>(!!params?.get('orderId'));

  const onClose = () => {
    setVisible(false);
    navigate(removeQueryParam(route.url, 'orderId'));
  };

  if (!visible) return null;

  const products = orders.value
    .find((o) => o.id === params.get('orderId'))
    ?.products?.map((p) => getProductById2(p.id))
    .filter((p) => !!p);

  if (!products?.length) return null;

  return (
    <>
      <div class="modal-backdrop show" style={{ display: 'block', zIndex: 999 }} />
      <div class="modal" style={{ display: 'block', zIndex: 999 }} onClick={onClose}>
        <div class="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
          <div class="modal-content">
            <div class="modal-body">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Замовлення створене!</h5>
                  <div className="mb-3">
                    {products.map((p) => (
                      <ProductListItem product={p} />
                    ))}
                  </div>
                  <button onClick={onClose} class="btn btn-primary">
                    Продовжити
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
