import { computed, signal } from '@preact/signals';
import { Product } from 'cosdb-types';
import { orders } from './orders';

export const products = signal<Product[]>([]);

export const getProductById = (id: string) => shopProducts.value.find((p) => p.id === id);

export const getProductById2 = (id: string) => products.value.find((p) => p.id === id);

const ordersInventory = computed(() => {
  return orders.value
    .filter((o) => o.status === 'OPEN')
    .reduce((acc: Record<string, number>, i) => {
      i.products.forEach((product) => {
        if (!acc[product.id]) acc[product.id] = 0;

        acc[product.id] += product.amount;
      });

      return acc;
    }, {});
});

export const shopProducts = computed<(Product & { available: number })[]>(() => {
  return products.value
    .map((p) => {
      const available =
        Object.values(p.inventory).reduce((acc, i) => acc + i.amount, 0) - (ordersInventory.value[p.id] || 0);

      return { ...p, available };
    })
    .filter((p) => p.available > 0);
});

console.log(shopProducts.value);
