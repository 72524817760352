import { ProductName } from '@/components/product-name';
import { ProductPricesWithCta } from '@/components/product-prices-with-cta';
import { COMPANY_ID } from '@/constants';
import { Product } from 'cosdb-types';

export const ProductListItem = ({ product }: { product: Product }) => {
  return (
    <div class="card mb-1">
      <div class="row g-0 align-items-center">
        <div class="col-md-3 p-2 text-center">
          {product.thumbnail && (
            <img
              style={{ maxHeight: 100 }}
              src={`https://storage.googleapis.com/cosdb2-thumbnails/c/${COMPANY_ID}/products/${(product.thumbnail || '').split('.')[0]}_480x480.webp`}
              class="img-fluid rounded-start"
              alt={product.name}
            />
          )}
        </div>
        <div class="col-md-9">
          <div class="card-body">
            <ProductName product={product} size="h6" clickable />
            <ProductPricesWithCta product={product} />
          </div>
        </div>
      </div>
    </div>
  );
};
