.FilteredProducts__filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin: 0.5rem 0 0;
}

.FilteredProducts__productsGrid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
}

.FilteredProducts__productsGrid:empty {
  grid-template-columns: 1fr;
}

.FilteredProducts__productsGrid:empty::before {
  content: 'За пошуковими крітеріями нічого не знайдено.';
  display: block;
  padding: 5rem;
  text-align: center;
  font-style: italic;
  opacity: 0.75;
}

@media (min-width: 768px) {
  .FilteredProducts__productsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

.productCard {
  transform: translateY(0);
  opacity: 1;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
}

@media (min-width: 1024px) {
  .productCard {
    cursor: pointer;
  }

  .productCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  }
}

@starting-style {
  .productCard {
    transform: translateY(15px);
    opacity: 0;
  }
}
