import { cart, isCartVisible } from '@/signals/cart';
import { getProductById } from '@/signals/products';
import { removeQueryParam } from '@/utils';
import { useRouter } from 'preact-router';
import { ProductListItem } from './product-list-item';

export const ShopList = () => {
  const [route, navigate] = useRouter();
  const queryParamsList = route.matches?.productIds || '';
  const queryParamsProductIds = queryParamsList.split(',').filter((i) => i);

  if (!queryParamsProductIds.length) return null;

  const onClose = () => {
    navigate(removeQueryParam(route.url, 'productIds'));
  };

  const onAddToCart = () => {
    cart.value = queryParamsProductIds;
    isCartVisible.value = true;
    onClose();
  };

  return (
    <>
      <div class="modal-backdrop show" style={{ display: 'block', zIndex: 998 }} />
      <div class="modal" style={{ display: 'block', zIndex: 998 }} onClick={onClose}>
        <div class="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Підбірка</h4>
              <button type="button" class="btn-close" onClick={onClose} />
            </div>
            <div class="modal-body">
              <div class="list-group mb-4">
                {queryParamsProductIds.length === 0 && <i>Нічого не обрано.</i>}
                {queryParamsProductIds.map((id) => {
                  const p = getProductById(id);

                  if (!p) return null;

                  return <ProductListItem product={p} />;
                })}
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" onClick={onClose}>
                Закрити
              </button>
              <button type="submit" class="btn btn-primary" onClick={onAddToCart}>
                Додати все в кошик
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
